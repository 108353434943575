<template>
  <div class="tradingV">
    <TradeView></TradeView>
  </div>
</template>

<script>
import TradeView from "../../pages/tradingview";
export default {
  name: 'TradingViews',
  components: { TradeView },
  mounted() {
    this.$store.commit("toggleTabbarShow", true);
    this.$store.commit("toggleTabbarCurrentShow", "Mt4");
  },
  
};
</script>

<style scoped>
.tradingV {
  height: calc(100vh - 50px);
}
</style>

