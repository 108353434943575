<template>
  <div id="tradingView_container" style="width: 100%; height: 100%;"></div>
</template>

<script>
import Tv from './script'
import { TvConfigInfo } from "./script/config"
import { GetSymbols } from "@/api/mt4Api";

export default {
  data() {
    return {
      name: TvConfigInfo.name,
      lang: '',
    }
  },
  created() {
    GetSymbols().then(res => {
      let symbolName = [];
      symbolName.push(...res.msg.CommonSymbol, ...res.msg.AllSymbol)
      localStorage.setItem("getSymbols", JSON.stringify(symbolName))
    })
    this.lang = localStorage.getItem("lang");
    TvConfigInfo.language = this.lang =='jp' ? 'ja' : this.lang == 'hk' ? 'zh' : this.lang;

    const querySymbol = this.$route.query;
    if(querySymbol.symbol) {
      this.name = querySymbol.symbol;
    }else {
      this.name = TvConfigInfo.name;
    }
    TvConfigInfo.name = this.name;
    TvConfigInfo.symbol = this.name;
    TvConfigInfo.description = this.name;
    TvConfigInfo.ticker = this.name;
    // 修改iframe内样式
  },
  mounted () {
    Tv.init(TvConfigInfo, null);
  },
}
</script>

<style>
#tv_chart_container {
  position: absolute;
  width: 100%;
  height: calc(100vh - 50px);
}
._tv-dialog._tv-dialog-nonmodal.ui-draggable {
  width: 100% !important;
}
</style>
